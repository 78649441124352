import React from "react";
// import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles(() => ({
//   root: {
//     display: "flex",
//     justifyContent: "space-between",
//   },
//   box: {
//     width: "30rem",
//     height: "10rem",
//     display: "flex",
//     alignItems: "center",
//     border: "1px solid rgba(0,0,0,0.1)",
//     borderRadius: "5px",
//     justifyContent: "center",
//   },

//   total: {
//     fontSize: "3rem",
//     paddingBottom: "0.75rem",
//     color: "green",
//   },
//   totalproduct: {
//     fontSize: "3rem",
//     paddingBottom: "0.75rem",
//     color: "purple",
//   },
//   totalcategory: {
//     fontSize: "3rem",
//     paddingBottom: "0.75rem",
//     color: "#c14747",
//   },
//   user: {
//     fontSize: "150px",
//     color: "green",
//   },
//   category: {
//     fontSize: "150px",
//     color: "#c14747",
//   },
//   product: {
//     fontSize: "150px",
//     color: "purple",
//   },
//   text: {
//     color: "rgba(0,0,0,0.5)",
//     fontSize: "1rem",
//   },
//   left: {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     marginLeft: "1rem",
//   },
// }));

// const data = {
//   labels: ["January", "February", "March", "April", "May", "June", "July"],
//   datasets: [
//     {
//       label: "My First Dataset",
//       data: [65, 59, 80, 81, 56, 55, 40],
//       backgroundColor: [
//         "rgba(255, 99, 132, 0.2)",
//         "rgba(255, 159, 64, 0.2)",
//         "rgba(255, 205, 86, 0.2)",
//         "rgba(75, 192, 192, 0.2)",
//         "rgba(54, 162, 235, 0.2)",
//         "rgba(153, 102, 255, 0.2)",
//         "rgba(201, 203, 207, 0.2)",
//       ],
//       borderColor: [
//         "rgb(255, 99, 132)",
//         "rgb(255, 159, 64)",
//         "rgb(255, 205, 86)",
//         "rgb(75, 192, 192)",
//         "rgb(54, 162, 235)",
//         "rgb(153, 102, 255)",
//         "rgb(201, 203, 207)",
//       ],
//       borderWidth: 1,
//     },
//   ],
// };
// const option = {
//   type: "bar",
//   data: data,
//   options: {
//     scales: {
//       y: {
//         beginAtZero: true,
//       },
//     },
//   },
// };

const Dashbroad = () => {
  return <div>Dashboard</div>;
};

export default Dashbroad;
